import { programAttributes } from '../program/ProgramAttributes'
import { deliverableAttributes } from '../program/deliverables/DeliverableAttributes'
import {
    justificationAttributes,
    monthsAttributes,
    spendAttributes,
} from '../program/discretionarySpend/DiscretionarySpendAttributes'
import { OBJECT_TYPE } from '../../Constant'
import {
    AliasRenderer,
    DifferencesRenderer,
    DifferencesTooltip,
} from './audit_tables/cell_renderers/CellRenderers'
import { DateRangePickerProps } from '@amzn/awsui-components-react'
import { ColDef } from 'ag-grid-community'
import {
    auditConcatenatedActionComparator,
    auditRevisionComparator,
    getUniqueOptions,
} from './AuditUtil'
import ClearFilterComponent from './ClearFilterComponent'
import { AuditState } from './useAuditState'
import _ from 'lodash'

export const PLAN_REQUIRE_FIELDS = ['year', 'plan_type']
export const REVISION_REQUIRE_FIELDS = ['revision_title', 'revision_status']
export const PROGRAM_REQUIRE_FIELDS = [
    'program_name',
    'stl_alias',
    'stl_name',
    'is_overhead_applied',
    'is_true_program',
]
export const DELIVERABLE_REQUIRE_FIELDS = [
    'deliverable_name',
    'delivery_year',
    'delivery_quarter',
    'priority',
    'category',
    'pillars',
]
export const HC_ESTIMATE_REQUIRE_FIELDS = ['job_function', 'hc_ct', 'hc_ff', 'hc_total']
export const DISCRETIONARY_SPEND_REQUIRE_FIELDS = [
    'ds_item_id_display',
    'expense_title',
    'expense_category',
    'expense_type',
    'cost_center',
    'description',
    'expense_note',
    'q1_justification',
    'q2_result',
    'total_expenditure',
]

export const enum ACTION_TYPES {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    COPY = 'COPY',
}

export const enum OBJECT_TYPES {
    PROGRAM = 'PROGRAM',
    DELIVERABLE = 'DELIVERABLE',
    PLAN = 'PLAN',
    REVISION = 'REVISION',
    GROUP = 'GROUP',
    ORG = 'ORG',
    PARENT_PROGRAM = 'PARENT_PROGRAM',
    HC_ESTIMATE = 'HC_ESTIMATE',
    DISCRETIONARY_SPEND = 'DISCRETIONARY_SPEND',
}

export enum LOG_GROUP_TO_OBJECT_TYPES {
    HEADCOUNT = 'HC_ESTIMATE',
    SPEND = 'DISCRETIONARY_SPEND',
}

export enum FILTER_PROPERTIES_PREFIX {
    DISCRETIONARY_SPEND = 'spend_',
    HC_ESTIMATE = 'hc_estimate_',
    DELIVERABLE = 'deliverable_',
}

export const DEFAULT_PAGE_SIZE_OPTIONS = [
    { label: '20', value: '20' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
]

const CREATED_UPDATED_FIELDS = ['created_by', 'created_at', 'updated_by', 'updated_at']
export const PLAN_FIELDS = [
    'year',
    'plan_type',
    'stl_leader',
    'admin_backup',
    'overhead_factor',
    'plan_status',
].concat(CREATED_UPDATED_FIELDS)
export const REVISION_FIELDS = ['revision_title', 'revision_description', 'revision_status'].concat(
    CREATED_UPDATED_FIELDS,
)
export const PROGRAM_FIELDS = programAttributes
    .map((attr) => attr.id)
    .slice(0, -4)
    .concat(['important_links', 'kingpin_goals'], CREATED_UPDATED_FIELDS)
export const DELIVERABLE__FIELDS = deliverableAttributes.map((attr) => attr.id)
export const HC_ESTIMATE_FIELDS = [
    'job_function',
    'is_active',
    'is_fte_months_selected',
    'is_overhead_applied',
    'deliverable_note',
    'hc_ct',
    'hc_ff',
    'hc_total',
    'hc_ct_overhead',
    'hc_ff_overhead',
    'hc_total_overhead',
    'fte_month_ct',
    'fte_month_ff',
    'fte_month_total',
    'fte_month_ct_overhead',
    'fte_month_ff_overhead',
    'fte_month_total_overhead',
].concat(CREATED_UPDATED_FIELDS)
export const DISCRETIONARY_SPEND_FIELDS = [
    ...spendAttributes,
    ...[{ id: 'total_expenditure' }],
    ...monthsAttributes(),
    ...justificationAttributes,
]
    .map((attr) => attr['id'])
    .concat(CREATED_UPDATED_FIELDS)
export const getObjectFields = (isRequiredFields: boolean, type: OBJECT_TYPE) => {
    let fields: string[] = []

    switch (type) {
        case OBJECT_TYPE.PLAN:
            fields = isRequiredFields ? PLAN_REQUIRE_FIELDS : PLAN_FIELDS
            break
        case OBJECT_TYPE.REVISION:
            fields = isRequiredFields ? REVISION_REQUIRE_FIELDS : REVISION_FIELDS
            break
        case OBJECT_TYPE.PROGRAM:
        case OBJECT_TYPE.PARENT_PROGRAM:
            fields = isRequiredFields ? PROGRAM_REQUIRE_FIELDS : PROGRAM_FIELDS
            break
        case OBJECT_TYPE.DELIVERABLE:
            fields = isRequiredFields ? DELIVERABLE_REQUIRE_FIELDS : DELIVERABLE__FIELDS
            break
        case OBJECT_TYPE.DISCRETIONARY_SPEND:
            fields = isRequiredFields
                ? DISCRETIONARY_SPEND_REQUIRE_FIELDS
                : DISCRETIONARY_SPEND_FIELDS
            break
        case OBJECT_TYPE.HC_ESTIMATE:
            fields = isRequiredFields ? HC_ESTIMATE_REQUIRE_FIELDS : HC_ESTIMATE_FIELDS
            break
    }

    return fields
}

export const AUDIT_KEY_REGEX = new RegExp('_sort|_id$')
export const IS_REGISTERED_USERS = 'registered_users'
export const Q3_OTHER_PROGRAMS = 'q3_other_programs'

export const BUSINESS_ENTITY_ID = 'business_entity_id'
export const AUDIT_SERVER_SIDE_FILTERS = {
    program_name: 'program_id',
    org_name: 'org_id',
    group_name: 'group_id',
    revision_name: 'revision_id',
    user_alias: 'user_alias',
    action_formatted: 'action_formatted',
    action_object_type: 'action_object_type',
}
export const SET_FILTER_LIMIT = 50

export const auditSetFilterParams = (values?: any, comparator?: Function) => {
    let defaultParams: any = {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        showTooltips: false,
    }

    if (values) {
        defaultParams = { ...defaultParams, values: values }
    }
    if (comparator) {
        defaultParams = { ...defaultParams, comparator: comparator }
    }
    return defaultParams
}

export interface ColumnGeneratorProps {
    state: AuditState
}

const generateRevisionColumn = (state) => {
    const selectedPlanId: string = state.selectPlanData?.selected?.value || ''
    const planRevisions = state.plansMap.data.get(selectedPlanId)?.revisions ?? []
    const revisionOptions = getUniqueOptions(planRevisions, 'revision_name')
    return {
        field: 'revision_name',
        headerName: 'Revision',
        headerClass: 'bold-header',
        filter: 'agSetColumnFilter',
        filterParams: auditSetFilterParams(revisionOptions, auditRevisionComparator),
    }
}

export const headcountTableColDefs = (props?: ColumnGeneratorProps): ColDef[] => {
    const spendDefs = spendTableColDefs(props)
    if (_.isEmpty(spendDefs)) {
        return []
    }
    return [
        ...spendDefs.slice(0, 2),
        {
            field: 'deliverable_name',
            headerClass: 'bold-header',
            headerName: 'Deliverable',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(),
        },
        ...spendDefs.slice(2),
    ]
}
const generateDateTimeColumn = () => {
    return { field: 'date', headerName: 'Date/Time', headerClass: 'bold-header', minWidth: 150 }
}
export const spendTableColDefs = (props?: ColumnGeneratorProps): ColDef[] => {
    if (!props) {
        return []
    }
    const { state } = props
    const { programsByPlan, orgsMap, groupsMap, userAliases } = state
    const selectedPlanId: string = state.selectPlanData?.selected?.value || ''
    const programs = getUniqueOptions(programsByPlan.data[selectedPlanId], 'program_name')
    const orgs = getUniqueOptions(orgsMap.data, 'org_name')
    const groups = getUniqueOptions(groupsMap.data, 'group_name')

    return [
        generateDateTimeColumn(),
        {
            field: 'program_name',
            headerName: 'Program',
            headerClass: 'bold-header',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(programs),
        },
        {
            field: 'org_name',
            headerName: 'Org',
            headerClass: 'bold-header',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(orgs),
        },
        {
            field: 'group_name',
            headerName: 'Group',
            headerClass: 'bold-header',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(groups),
        },
        {
            field: 'user_alias',
            headerName: 'User',
            cellRenderer: AliasRenderer,
            headerClass: 'bold-header',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(userAliases.data),
        },
        {
            field: 'action_formatted',
            headerClass: 'bold-header',
            headerName: 'Action',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(['Copy', 'Create', 'Delete', 'Update']),
        },
        generateRevisionColumn(state),
        generateDifferencesColumnDefinition(),
    ]
}

export const programDeliverableTableColDefs = (props?: ColumnGeneratorProps): ColDef[] => {
    if (!props) {
        return []
    }
    const { state } = props
    const { programsByPlan, userAliases } = state
    const selectedPlanId: string = state.selectPlanData?.selected?.value || ''
    const programs = getUniqueOptions(programsByPlan.data[selectedPlanId], 'program_name')
    return [
        generateDateTimeColumn(),
        {
            field: 'program_name',
            headerName: 'Program',
            headerClass: 'bold-header',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(programs),
        },
        {
            field: 'user_alias',
            headerName: 'User',
            cellRenderer: AliasRenderer,
            headerClass: 'bold-header',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(userAliases.data),
        },
        {
            field: 'action_object_type',
            headerClass: 'bold-header',
            headerName: 'Action',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(
                [
                    'Copy Deliverable',
                    'Create Deliverable',
                    'Delete Deliverable',
                    'Update Deliverable',
                    'Create Program',
                    'Delete Program',
                    'Update Program',
                ],
                auditConcatenatedActionComparator,
            ),
        },
        generateRevisionColumn(state),
        generateDifferencesColumnDefinition(),
    ]
}

export const planRevisionTableColDefs = (props): ColDef[] => {
    const { state } = props
    const { userAliases } = state

    return [
        generateDateTimeColumn(),
        { field: 'plan_name', headerName: 'Plan Name', headerClass: 'bold-header' },
        {
            field: 'user_alias',
            headerName: 'Alias',
            cellRenderer: AliasRenderer,
            headerClass: 'bold-header',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(userAliases.data),
        },
        {
            field: 'action_object_type',
            headerClass: 'bold-header',
            headerName: 'Action',
            filter: 'agSetColumnFilter',
            filterParams: auditSetFilterParams(
                [
                    'Create Plan',
                    'Delete Plan',
                    'Update Plan',
                    'Create Revision',
                    'Delete Revision',
                    'Update Revision',
                ],
                auditConcatenatedActionComparator,
            ),
        },
        generateDifferencesColumnDefinition(),
    ]
}

const generateDifferencesColumnDefinition = (): ColDef => {
    return {
        field: 'differences',
        headerName: 'Changes',
        cellRenderer: DifferencesRenderer,
        cellClass: 'differences-cell',
        autoHeight: true,
        headerClass: 'bold-header',
        minWidth: 750,
        filter: 'agNumberColumnFilter',
        floatingFilterComponent: ClearFilterComponent,
        suppressHeaderFilterButton: true,
        suppressFloatingFilterButton: true,
        tooltipComponent: DifferencesTooltip,
        tooltipField: 'differences',
    }
}

export const enum AUDIT_LOG_GROUPS {
    HEADCOUNT = 'HEADCOUNT',
    PROGRAM_DELIVERABLE = 'PROGRAM_DELIVERABLE',
    PLAN_REVISION = 'PLAN_REVISION',
    SPEND = 'SPEND',
}

export const AUDIT_DATE_RANGE_PICKER_RELATIVE_OPTIONS: DateRangePickerProps.RelativeOption[] = [
    {
        key: 'previous-3-days',
        amount: 3,
        unit: 'day',
        type: 'relative',
    },
    {
        key: 'previous-7-days',
        amount: 7,
        unit: 'day',
        type: 'relative',
    },
    {
        key: 'previous-4-weeks',
        amount: 4,
        unit: 'week',
        type: 'relative',
    },
    {
        key: 'previous-3-months',
        amount: 3,
        unit: 'month',
        type: 'relative',
    },
    {
        key: 'previous-6-months',
        amount: 6,
        unit: 'month',
        type: 'relative',
    },
    {
        key: 'previous-1-year',
        amount: 1,
        unit: 'year',
        type: 'relative',
    },
]

export const AUDIT_DATE_RANGE_PICKER_I18N_STRINGS = {
    todayAriaLabel: 'Today',
    nextMonthAriaLabel: 'Next month',
    previousMonthAriaLabel: 'Previous month',
    customRelativeRangeDurationLabel: 'Duration',
    customRelativeRangeDurationPlaceholder: 'Enter duration',
    customRelativeRangeOptionLabel: 'Range',
    customRelativeRangeOptionDescription: 'Select a range by days, weeks, months and year.',
    customRelativeRangeUnitLabel: 'Unit of time',
    formatRelativeRange: (e) => {
        const unitStr = 1 === e.amount ? e.unit : `${e.unit}s`
        return `Last ${e.amount} ${unitStr} `
    },
    formatUnit: (e, t) => (1 === t ? e : `${e}s`),
    relativeModeTitle: 'Relative range',
    absoluteModeTitle: 'Absolute range',
    relativeRangeSelectionHeading: 'Choose a range',
    startDateLabel: 'Start date',
    endDateLabel: 'End date',
    startTimeLabel: 'Start time',
    endTimeLabel: 'End time',
    clearButtonLabel: 'Clear and dismiss',
    cancelButtonLabel: 'Cancel',
    applyButtonLabel: 'Apply',
}

export const getColumnsForState = (props: ColumnGeneratorProps) => {
    const { activeTabId } = props.state
    switch (activeTabId) {
        case AUDIT_LOG_GROUPS.HEADCOUNT:
            return headcountTableColDefs(props)
        case AUDIT_LOG_GROUPS.SPEND:
            return spendTableColDefs(props)
        case AUDIT_LOG_GROUPS.PLAN_REVISION:
            return planRevisionTableColDefs(props)
        case AUDIT_LOG_GROUPS.PROGRAM_DELIVERABLE:
            return programDeliverableTableColDefs(props)
    }
}

export enum COLUMN_ID_FILTER_LIST_MAP {
    program_name = 'programsByPlan',
    org_name = 'orgsMap',
    group_name = 'groupsMap',
    user_alias = 'userAliases',
    action_formatted = 'action_formatted',
    action_object_type = 'action_object_type',
    revision_name = 'revisionsMap',
}

// ['Copy', 'Create', 'Delete', 'Update']
export const ACTION_FORMATTED_FILTER_SIZE = 4
// ['Copy Deliverable', 'Create Deliverable', 'Delete Deliverable', 'Update Deliverable', 'Create Program', 'Delete Program', 'Update Program']
export const PROGRAM_DELIVERABLE_ACTION_OBJECT_TYPE_FILTER_SIZE = 7
// ['Create Plan', 'Delete Plan', 'Update Plan', 'Create Revision', 'Delete Revision', 'Update Revision']
export const PLAN_REVISION_ACTION_OBJECT_TYPE_FILTER_SIZE = 6
export const ONE_HUNDRED_PERCENT = 1
