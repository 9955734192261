import { SUMMARY_TABS } from '../../Constant'
import { hideZerosAndTruncateNumbers } from './SummaryUtil'
import { ACTION_TYPES } from './useNewSummaryData'
import styled from 'styled-components'
import {
    COL_LIST,
    COLUMNS,
    filterColumns,
    getPinnedRows,
    GRAND_TOTAL_ROW,
    PLAN,
    REVISION,
    ROW_LIST,
    ROWS,
    TAB,
} from './PreferencesUtil'
import _ from 'lodash'
import {
    filterAndSortEstimatesByPreferences,
    processSpendEstimates,
    SpendTableColumns,
} from './NewSpendSummaryUtils'
import * as xlsx from 'xlsx'
import { Icon } from '@amzn/awsui-components-react'

const HC_TYPE = 'headcount_type'

export const ATL_COLOR = 'rgba(70, 227, 114, 0.1)'
export const DELIVERABLE_BTL_COLOR = 'rgba(255, 0, 92, 0.1)'
export const PROGRAM_BTL_COLOR = 'rgba(255, 0, 92, 0.1)'
export const ATL_BTL_ARROW_SIZE = 13
export const PROGRAM_PRIORITY_SIZE = 15
export const PROGRAM_PRIORITY_BADGE_SIZE = 20
export const ROW_HEIGHT = 35
export const TOTAL_LABEL = 'Total'
const CELL_SM = 15
const CELL_LG = 40

export enum SORT_OPTIONS {
    NAME = 'name',
    PRIORITY = 'priority',
}

const CustomDeliverableBadge = styled.span<{ $atl: boolean }>`
    margin: 2rem;
    background: ${(props) => (props.$atl ? ATL_COLOR : DELIVERABLE_BTL_COLOR)};
    border-radius: 80%;
    padding: 0.5rem;
`
const NameBadge = styled.span`
    margin-left: 6.5rem;
`
const generateGroupHeadcountKey = (item, hcType) => {
    return `${item.org_id}_${item.group_id}_${hcType}`
}

const generateOrgHeadcountKey = (item, hcType) => {
    return `${item.org_id}_${hcType}`
}

const ignoreGroup = (group: any, groupOHData: any) => {
    return (
        group?.archived &&
        !(
            groupOHData[generateGroupHeadcountKey(group, 'ct')] +
            groupOHData[generateGroupHeadcountKey(group, 'ff')]
        )
    )
}

const getGroupStatus = (orgGroups, hcEstimate) => {
    // find the org associated with the hc
    const orgGroup = orgGroups.filter((org) => hcEstimate.org_id === org.orgId)
    let groupStatus = false
    if (orgGroup.length) {
        const group = orgGroup[0]['groups'].filter(
            (group) => hcEstimate.group_id === group.group_id,
        )

        if (group.length) {
            groupStatus = group[0]['is_active']
        }
    }
    return groupStatus
}
const createRowMapFromDeliverables = (deliverables, programData = {}) => {
    const rowMap: any = {}

    Object.entries(deliverables).forEach((entry: any) => {
        const program_name = entry[0]
        const deliverableList = entry[1]

        if (!program_name || !deliverableList) {
            return
        }

        Array.from(deliverableList).forEach((deliverable: any) => {
            const programId = deliverableList[0]?.program_id
            const isOpGoal = programData[deliverable.program_id]?.is_op_goal === 'True'
            const rowId = `${deliverable.deliverable_id}#${programId}`
            const priority = programData[programId]?.priority
            const displaySeq = programData[programId]?.display_seq
            const ohApplied = programData[programId]?.is_overhead_applied

            if (deliverable?.is_active) {
                rowMap[rowId] = {
                    row_id: rowId,
                    program_name: program_name,
                    priority: priority,
                    display_sequence: displaySeq,
                    is_op_goal: isOpGoal,
                    deliverable_name: {
                        name: deliverable?.deliverable_name,
                        priority: deliverable?.priority,
                    },
                    deliverable_id: deliverable?.deliverable_id,
                    program_id: programId,
                    hc_ct: 0,
                    hc_ff: 0,
                    row_total: 0,
                    total_ct: 0,
                    total_ff: 0,
                    scoped: false,
                    is_oh_applied: ohApplied,
                }
            }
        })
    })

    return rowMap
}

const deliverableComparator = (a, b, isDesc) => a.name.trim().localeCompare(b.name.trim())

const leftPinnedColumns = [
    {
        headerName: 'C&T',
        field: 'hc_ct',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    },
    {
        headerName: 'FF',
        field: 'hc_ff',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    },
    {
        headerName: `Total`,
        field: 'row_total',
        width: 100,
        aggFunc: 'sum',
        colId: 'TotalAggRow',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
        headerTooltip: 'Total (C&T + FF)',
    },
]

const addValueToKeys = (data, value, keys) => {
    keys.forEach((key) => {
        if (!data[key]) {
            data[key] = 0
        }
        data[key] += value
    })
}

const setOrgColumns = (groupOHData, data, initialOrgs) => {
    const colDef = [...orgSummaryInitialColDef, ...leftPinnedColumns]

    const openByDefault = initialOrgs.length === 1

    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            if (initialOrgs.includes(org.orgName)) {
                const children: any = []
                const orgId = org.orgId
                const orgName = org.orgName

                org.groups.forEach((group) => {
                    if (ignoreGroup(group, groupOHData)) {
                        return
                    }
                    const suffix = group?.archived ? ' (archived)' : ''
                    const groupName = `${group.group_name}${suffix}`
                    const groupColDef = {
                        columnGroupShow: 'open',
                        headerName: groupName,
                        field: `${orgId}_${group.group_id}`,
                        colId: `${orgId}_${group.group_id}_id`,
                        groupId: `${orgId}_${group.group_id}`,
                        valueFormatter: (params) => {
                            return params.value ? params.value : 0
                        },
                        width: 100,
                        headerTooltip: groupName,
                        children: [
                            {
                                columnGroupShow: 'open',
                                colId: `${orgId}_${group.group_id}_ct_id`,
                                headerName: 'C&T',
                                field: `${orgId}_${group.group_id}_ct`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                            {
                                columnGroupShow: 'open',
                                colId: `${orgId}_${group.group_id}_ff_id`,
                                headerName: 'FF',
                                field: `${orgId}_${group.group_id}_ff`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                            {
                                columnGroupShow: 'open',
                                colId: `${orgId}_${group.group_id}_total_id`,
                                headerName: 'Total',
                                field: `${orgId}_${group.group_id}_total`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                        ],
                    }

                    children.push(groupColDef)
                })

                children.push({
                    headerName: 'C&T',
                    field: `${orgId}_ct`,
                    aggFunc: 'sum',
                    colId: `${orgId}_ct_id`,
                    columnGroupShow: 'closed',
                    cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                })
                children.push({
                    headerName: 'FF',
                    field: `${orgId}_ff`,
                    colId: `${orgId}_ff_id`,
                    aggFunc: 'sum',
                    columnGroupShow: 'closed',
                    cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                })
                children.push({
                    headerName: 'Total',
                    field: `${orgId}_total`,
                    colId: `${orgId}_total_id`,
                    aggFunc: 'sum',
                    columnGroupShow: 'closed',
                    cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                })

                const col = {
                    headerName: orgName,
                    field: orgName,
                    colId: `${orgId}_id`,
                    groupId: `${orgId}_group_id`,
                    width: 100,
                    aggFunc: 'sum',
                    headerTooltip: orgName,
                    children: children,
                    openByDefault: openByDefault,
                }

                colDef.push(col)
            }
        })
    }

    return { colDef }
}

const setOrgRows = (data, orgsToInclude: string[]) => {
    const orgData = data[ACTION_TYPES.HC].data
    const deliverables = data[ACTION_TYPES.DELIVERABLES].data
    const programData = data[ACTION_TYPES.PROGRAM].data
    const orgGroups = data[ACTION_TYPES.GROUPS_BY_ORG].data
    const ohFactor = Number(data[ACTION_TYPES.PLAN].selected?.data.overhead_factor)
    const rowMap = createRowMapFromDeliverables(deliverables, programData)
    const groupOHData = { program_name: 'Total OH' }
    const bisData = { program_name: 'BIS Total' }

    for (const hcEstimate of orgData) {
        if (!orgsToInclude.includes(hcEstimate.org_id)) continue

        const rowId = `${hcEstimate.deliverable_id}#${hcEstimate.program_id}`
        const ohApplied = programData[hcEstimate.program_id]?.is_overhead_applied

        if (rowId in rowMap) {
            const row = rowMap[rowId]
            const groupStatus = getGroupStatus(orgGroups, hcEstimate)
            row.row_id = rowId

            if (HC_TYPE in hcEstimate && groupStatus) {
                const hcType = hcEstimate.headcount_type

                // set the left pinned data
                row[`hc_${hcType}`] += hcEstimate.headcount_value
                row[`total_${hcType}`] += hcEstimate.headcount_value
                row[`row_total`] += hcEstimate.headcount_value

                // set the group data
                row[generateGroupHeadcountKey(hcEstimate, hcType)] = hcEstimate.headcount_value

                if (!row[`${hcEstimate.org_id}_${hcType}`]) {
                    row[`${hcEstimate.org_id}_${hcType}`] = 0
                }

                row[`${hcEstimate.org_id}_${hcType}`] += hcEstimate.headcount_value

                if (!row[`${hcEstimate.org_id}_total`]) {
                    row[`${hcEstimate.org_id}_total`] = 0
                }
                row[`${hcEstimate.org_id}_total`] += hcEstimate.headcount_value

                const groupTotalKey = generateGroupHeadcountKey(hcEstimate, 'total')
                if (!row[groupTotalKey]) {
                    row[groupTotalKey] = 0
                }
                row[groupTotalKey] += hcEstimate.headcount_value

                let ohValue = hcEstimate.headcount_value

                if (ohApplied && ohFactor) {
                    ohValue = (1 + ohFactor / 100) * hcEstimate.headcount_value
                }

                const headcountKey = generateGroupHeadcountKey(hcEstimate, hcType)
                const orgKey = generateOrgHeadcountKey(hcEstimate, hcType)

                const totalKey = generateGroupHeadcountKey(hcEstimate, 'total')
                const orgTotalKey = generateOrgHeadcountKey(hcEstimate, 'total')

                addValueToKeys(groupOHData, ohValue, [headcountKey, orgKey, totalKey, orgTotalKey])
            }
        }
    }

    // BIS data
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            const orgId = org.orgId
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                bisData[generateGroupHeadcountKey(group, 'ct')] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                bisData[generateGroupHeadcountKey(group, 'ff')] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0

                orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
            })

            bisData[`${orgId}_ct`] = orgCT
            bisData[`${orgId}_ff`] = orgFF
            bisData[`${orgId}_total`] = orgCT + orgFF
        })
    }

    const gapData = {}
    Object.entries(bisData).forEach((value: any) => {
        const totalOHHC = groupOHData[value[0]] ?? 0
        const bisHC = value[1]
        gapData[value[0]] = totalOHHC - bisHC
    })
    gapData['program_name'] = 'Gap (Total w/ OH - BIS)'

    const flatData = Object.values(rowMap)

    return { flatData, groupOHData, bisData, gapData }
}

const setGroupColumns = (data, initialGroups) => {
    const ctColDef = {
        headerName: 'C&T',
        field: 'hc_ct',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    }

    const ffColDef = {
        headerName: 'FF',
        field: 'hc_ff',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    }

    const rowHCAggColDef = {
        headerName: `Total`,
        field: 'row_total',
        width: 100,
        aggFunc: 'sum',
        colId: 'TotalAggRow',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
        headerTooltip: 'Total (C&T + FF)',
    }

    const colDef = [...orgSummaryInitialColDef, ctColDef, ffColDef, rowHCAggColDef]

    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            const orgId = org.orgId
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                if (initialGroups.includes(group.group_name)) {
                    const groupColDef = {
                        headerName: group.group_name,
                        field: `${orgId}_${group.group_id}`,
                        colId: `${orgId}_${group.group_id}_id`,
                        groupId: `${orgId}_${group.group_id}`,
                        valueFormatter: (params) => {
                            return params.value ? params.value : 0
                        },
                        width: 100,
                        headerTooltip: `${group.group_name}`,
                        children: [
                            {
                                colId: `${orgId}_${group.group_id}_ct_id`,
                                headerName: 'C&T',
                                field: `${orgId}_${group.group_id}_ct`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                            {
                                colId: `${orgId}_${group.group_id}_ff_id`,
                                headerName: 'FF',
                                field: `${orgId}_${group.group_id}_ff`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                            {
                                colId: `${orgId}_${group.group_id}_total_id`,
                                headerName: 'Total',
                                field: `${orgId}_${group.group_id}_total`,
                                aggFunc: 'sum',
                                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                            },
                        ],
                    }

                    colDef.push(groupColDef)

                    orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                    orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                }
            })
        })
    }
    return { colDef }
}

// TODO refactor - same as org
const setGroupRows = (data, groupsToInclude: string[]) => {
    const orgData = data[ACTION_TYPES.HC].data
    const deliverables = data[ACTION_TYPES.DELIVERABLES].data
    const orgGroups = data[ACTION_TYPES.GROUPS_BY_ORG].data
    const programData = data[ACTION_TYPES.PROGRAM].data
    const ohFactor = Number(data[ACTION_TYPES.PLAN].selected?.data.overhead_factor)

    const rowMap = createRowMapFromDeliverables(deliverables, programData)
    const groupOHData = { program_name: 'Total OH' }
    const bisData = { program_name: 'BIS Total' }

    for (const hcEstimate of orgData) {
        if (!groupsToInclude.includes(hcEstimate.group_id)) continue

        const rowId = `${hcEstimate.deliverable_id}#${hcEstimate.program_id}`
        const ohApplied = programData[hcEstimate.program_id]?.is_overhead_applied

        if (rowId in rowMap) {
            const row = rowMap[rowId]
            row.row_id = rowId
            const groupStatus = getGroupStatus(orgGroups, hcEstimate)

            if (HC_TYPE in hcEstimate && groupStatus) {
                const hcType = hcEstimate.headcount_type

                // set the left pinned data
                row[`hc_${hcType}`] += hcEstimate.headcount_value
                row[`total_${hcType}`] += hcEstimate.headcount_value
                row[`row_total`] += hcEstimate.headcount_value

                // set the group data
                row[generateGroupHeadcountKey(hcEstimate, hcType)] = hcEstimate.headcount_value

                if (!row[generateGroupHeadcountKey(hcEstimate, 'total')]) {
                    row[generateGroupHeadcountKey(hcEstimate, 'total')] = 0
                }
                row[generateGroupHeadcountKey(hcEstimate, 'total')] += hcEstimate.headcount_value

                let ohValue = hcEstimate.headcount_value
                if (ohApplied && ohFactor) {
                    const ohValue = (1 + ohFactor / 100) * hcEstimate.headcount_value
                }

                const headcountKey = generateGroupHeadcountKey(hcEstimate, hcType)
                const totalKey = generateGroupHeadcountKey(hcEstimate, 'total')

                addValueToKeys(groupOHData, ohValue, [headcountKey, totalKey])
            }
        }
    }

    // BIS data
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            const orgId = org.orgId
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                const groupCT = 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                const groupFF = 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0

                bisData[generateGroupHeadcountKey(group, 'ct')] = groupCT
                bisData[generateGroupHeadcountKey(group, 'ff')] = groupFF
                bisData[generateGroupHeadcountKey(group, 'total')] = groupCT + groupFF

                orgFF += groupFF
                orgCT += groupCT
            })

            bisData[`${orgId}_ct`] = orgCT
            bisData[`${orgId}_ff`] = orgFF
            bisData[`${orgId}_total`] = orgCT + orgFF
        })
    }

    const gapData = {}
    Object.entries(bisData).forEach((value: any) => {
        const totalOHHC = groupOHData[value[0]] ?? 0
        const bisHC = value[1]
        gapData[value[0]] = totalOHHC - bisHC
    })
    gapData['program_name'] = 'Gap (Total w/ OH - BIS)'

    const flatData = Object.values(rowMap)

    return { flatData, groupOHData, bisData, gapData }
}

const setProgramColumns = (groupOHData, groups, initialOrgs) => {
    const deliverableColDef = {
        headerName: 'Deliverable',
        field: 'deliverable_name',
        width: 300,
        pinned: 'left',
        headerTooltip: 'Deliverable',
        cellRenderer: (params) => deliverableCellRenderer(params, true),
        valueFormatter: deliverableValueFormatter,
        filter: 'agSetColumnFilter',
        filterParams: {
            valueFormatter: deliverableFilterValueFormatter,
            comparator: deliverableComparator,
        },
        floatingFilter: true,

        comparator: deliverableComparator,
    }

    const ctColDef = {
        headerName: 'C&T',
        field: 'hc_ct',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    }

    const ffColDef = {
        headerName: 'FF',
        field: 'hc_ff',
        width: 100,
        aggFunc: 'sum',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
    }

    const rowHCAggColDef = {
        headerName: `Total`,
        field: 'row_total',
        width: 100,
        aggFunc: 'sum',
        colId: 'TotalAggRow',
        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
        pinned: 'left',
        headerTooltip: 'Total (C&T + FF)',
    }

    const totalColDef = {
        headerName: 'TOTAL',
        field: 'parent_total',
        pinned: 'left',
        children: [ctColDef, ffColDef, rowHCAggColDef],
    }

    const colDef: any[] = [deliverableColDef, totalColDef]

    const bisData = { deliverable_name: 'BIS Total' }

    groups.forEach((org) => {
        if (initialOrgs.includes(org.orgName)) {
            const children: any = []
            const orgId = org.orgId
            const orgName = org.orgName
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                const suffix = group?.archived ? ' (archived)' : ''
                const groupColDef = {
                    columnGroupShow: 'open',
                    headerName: `${group.group_name}${suffix}`,
                    field: `${orgId}_${group.group_id}`,
                    colId: `${orgId}_${group.group_id}_id`,
                    groupId: `${orgId}_${group.group_id}`,
                    valueFormatter: (params) => {
                        return params.value ? params.value : 0
                    },
                    width: 100,
                    headerTooltip: `${group.group_name}`,
                    children: [
                        {
                            columnGroupShow: 'open',
                            colId: `${orgId}_${group.group_id}_ct_id`,
                            headerName: 'C&T',
                            field: `${orgId}_${group.group_id}_ct`,
                            aggFunc: 'sum',
                            cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                        },
                        {
                            columnGroupShow: 'open',
                            colId: `${orgId}_${group.group_id}_ff_id`,
                            headerName: 'FF',
                            field: `${orgId}_${group.group_id}_ff`,
                            aggFunc: 'sum',
                            cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                        },
                    ],
                }

                children.push(groupColDef)
                bisData[generateGroupHeadcountKey(group, 'ct')] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                bisData[generateGroupHeadcountKey(group, 'ff')] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0

                orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
            })

            bisData[`${orgId}_ct`] = orgCT
            bisData[`${orgId}_ff`] = orgFF
            bisData[`${orgId}_total`] = orgCT + orgFF

            children.push({
                headerName: 'C&T',
                field: `${orgId}_ct`,
                aggFunc: 'sum',
                colId: `${orgId}_ct_id`,
                columnGroupShow: 'closed',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })
            children.push({
                headerName: 'FF',
                field: `${orgId}_ff`,
                colId: `${orgId}_ff_id`,
                aggFunc: 'sum',
                columnGroupShow: 'closed',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })
            children.push({
                headerName: 'Total',
                field: `${orgId}_total`,
                colId: `${orgId}_total_id`,
                aggFunc: 'sum',
                columnGroupShow: 'closed',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })

            const col = {
                headerName: orgName,
                field: orgName,
                colId: `${orgId}_id`,
                groupId: `${orgId}_group_id`,
                width: 100,
                aggFunc: 'sum',
                headerTooltip: orgName,
                children: children,
            }

            colDef.push(col)
        }
    })

    const gapData = {}
    Object.entries(bisData).forEach((value: any) => {
        const totalOHHC = groupOHData[value[0]] ?? 0
        const bisHC = value[1]
        gapData[value[0]] = totalOHHC - bisHC
    })
    gapData['deliverable_name'] = 'Gap (Total w/ OH - BIS)'

    return { colDef, bisData, gapData }
}

const setProgramRows = (orgData, data, deliverables) => {
    const orgGroups = data[ACTION_TYPES.GROUPS_BY_ORG].data
    const rowMap = createRowMapFromDeliverables(deliverables)
    const groupOHData = { deliverable_name: 'Total OH' }
    const orgGroupTotals = {}

    orgData.forEach((hcEstimate: any) => {
        const rowId = `${hcEstimate.deliverable_id}#${hcEstimate.program_id}`
        if (rowId in rowMap) {
            const row = rowMap[rowId]
            row.row_id = rowId
            const groupStatus = getGroupStatus(orgGroups, hcEstimate)
            if (HC_TYPE in hcEstimate && groupStatus) {
                const hcType = hcEstimate.headcount_type

                // set the left pinned data
                row[`hc_${hcType}`] += hcEstimate.headcount_value
                row[`total_${hcType}`] += hcEstimate.headcount_value
                row['row_total'] += hcEstimate.headcount_value

                // set the group data
                const headcountKey = generateGroupHeadcountKey(hcEstimate, hcType)
                row[generateGroupHeadcountKey(hcEstimate, hcType)] = hcEstimate.headcount_value
                if (!orgGroupTotals[headcountKey]) {
                    orgGroupTotals[headcountKey] = 0
                }
                orgGroupTotals[headcountKey] += hcEstimate.headcount_value

                if (!row[`${hcEstimate.org_id}_${hcType}`]) {
                    row[`${hcEstimate.org_id}_${hcType}`] = 0
                }

                row[`${hcEstimate.org_id}_${hcType}`] += hcEstimate.headcount_value

                if (!row[`${hcEstimate.org_id}_total`]) {
                    row[`${hcEstimate.org_id}_total`] = 0
                }
                row[`${hcEstimate.org_id}_total`] += hcEstimate.headcount_value
            }
        }
    })

    const flatData = Object.values(rowMap)

    return { flatData, groupOHData, orgGroupTotals }
}

const setSpendRows = (initialOrgs, data) => {
    const { allEstimateRows, estimateTotalsByOrgGroup } = processSpendEstimates(data, initialOrgs)
    return filterAndSortEstimatesByPreferences(allEstimateRows, estimateTotalsByOrgGroup)
}

const setPlanColumns = (data) => {
    const colDef = [...orgSummaryInitialColDef, ...leftPinnedColumns]
    const be = data[ACTION_TYPES.BE]
    const beName = be?.business_entity_name

    const orgData = data[ACTION_TYPES.GROUPS_BY_ORG].data?.sort((a, b) =>
        a.orgName.localeCompare(b.orgName),
    )

    const childrenColDef: any[] = []

    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        orgData.forEach((org) => {
            const children: any = []
            const orgId = org.orgId
            const orgName = org.orgName

            children.push({
                headerName: 'C&T',
                field: `${orgId}_ct`,
                aggFunc: 'sum',
                colId: `${orgId}_ct_id`,
                columnGroupShow: 'open',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })

            children.push({
                headerName: 'FF',
                field: `${orgId}_ff`,
                colId: `${orgId}_ff_id`,
                aggFunc: 'sum',
                columnGroupShow: 'open',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })

            children.push({
                headerName: 'Total',
                field: `${orgId}_total`,
                colId: `${orgId}_total_id`,
                aggFunc: 'sum',
                columnGroupShow: 'open',
                cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            })

            const col = {
                headerName: orgName,
                field: orgName,
                colId: `${orgId}_id`,
                groupId: `${orgId}_group_id`,
                width: 100,
                columnGroupShow: 'open',
                aggFunc: 'sum',
                headerTooltip: orgName,
                children: children,
            }

            childrenColDef.push(col)
        })
    }

    const BEColDef = {
        headerName: beName,
        field: 'business_entity_name',
        width: 200,
        headerTooltip: 'Business Entity',
        children: childrenColDef,
    }

    colDef.push(BEColDef)

    return { colDef }
}

const setPlanRows = (data) => {
    const orgData = data[ACTION_TYPES.HC].data
    const deliverables = data[ACTION_TYPES.DELIVERABLES].data
    const orgGroups = data[ACTION_TYPES.GROUPS_BY_ORG].data
    const programData = data[ACTION_TYPES.PROGRAM].data
    const ohFactor = Number(data[ACTION_TYPES.PLAN].selected?.data.overhead_factor)

    const rowMap = createRowMapFromDeliverables(deliverables, programData)
    const groupOHData = { program_name: 'Total OH' }
    const bisData = { program_name: 'BIS Total' }

    orgData.forEach((hcEstimate: any) => {
        const ohApplied = programData[hcEstimate.program_id]?.is_overhead_applied
        const rowId = `${hcEstimate.deliverable_id}#${hcEstimate.program_id}`

        if (rowId in rowMap) {
            const row = rowMap[rowId]
            row.row_id = rowId
            const groupStatus = getGroupStatus(orgGroups, hcEstimate)

            if (HC_TYPE in hcEstimate && groupStatus) {
                const hcType = hcEstimate.headcount_type

                // set the left pinned data
                row[`hc_${hcType}`] += hcEstimate.headcount_value
                row[`total_${hcType}`] += hcEstimate.headcount_value
                row[`row_total`] += hcEstimate.headcount_value

                // set the org data
                const orgKey = generateOrgHeadcountKey(hcEstimate, hcType)
                const orgTotalKey = generateOrgHeadcountKey(hcEstimate, 'total')
                let ohValue = hcEstimate.headcount_value

                addValueToKeys(row, ohValue, [orgKey, orgTotalKey])

                if (ohApplied && ohFactor) {
                    const ohValue = (1 + ohFactor / 100) * hcEstimate.headcount_value
                }

                addValueToKeys(groupOHData, ohValue, [orgKey, orgTotalKey])
            }
        }
    })

    // BIS data
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            const orgId = org.orgId
            let orgFF = 0
            let orgCT = 0

            org.groups.forEach((group) => {
                bisData[generateGroupHeadcountKey(group, 'ct')] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                bisData[generateGroupHeadcountKey(group, 'ff')] =
                    'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0

                orgFF += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                orgCT += 'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
            })

            bisData[`${orgId}_ct`] = orgCT
            bisData[`${orgId}_ff`] = orgFF
            bisData[`${orgId}_total`] = orgCT + orgFF
        })
    }

    const gapData = {}
    Object.entries(bisData).forEach((value: any) => {
        const totalOHHC = groupOHData[value[0]] ?? 0
        const bisHC = value[1]
        gapData[value[0]] = totalOHHC - bisHC
    })

    gapData['program_name'] = 'Gap (Total w/ OH - BIS)'

    const flatData = Object.values(rowMap)

    return { flatData, groupOHData, bisData, gapData }
}

const deduplicateArchivedGroups = (duplicateGroups: any[][], groupOHData) => {
    // archived items have same group ID and name as non-archived ones (archived after move group)
    // we have to figure out which group to keep in the group summary
    // otherwise we'll have a bunch of duplicate columns
    let groupsToFilterOut: string[] = []
    const mapToOrgGroupId = (groupList) => {
        return groupList.map((gp) => `${gp.org_id}_${gp.group_id}`)
    }
    duplicateGroups.forEach((duplicates) => {
        // if archived group has HC, use that. otherwise, return the non archived group
        let groupToKeep: string[] = mapToOrgGroupId(
            duplicates.filter(
                (gp) => gp?.archived && groupOHData[generateGroupHeadcountKey(gp, 'total')],
            ),
        )
        if (!groupToKeep.length) {
            groupToKeep = mapToOrgGroupId(duplicates.filter((gp) => !gp?.archived))
        }
        // track the org-group mappings we want to exclude from the column list
        groupsToFilterOut = [
            ...groupsToFilterOut,
            ...mapToOrgGroupId(
                duplicates.filter((dup) => !groupToKeep.includes(`${dup.org_id}_${dup.group_id}`)),
            ),
        ]
    })
    return groupsToFilterOut
}

export function setRowsAndColumns(
    tab: SUMMARY_TABS,
    data,
    initialOrgs,
    initialGroups,
    initialColumns,
    initialRows,
) {
    // Clear out the rows and columns
    data.gridApi.showLoadingOverlay()
    data.gridApi.setGridOption('columnDefs', [])
    data.gridApi.applyTransaction({
        remove: [],
    })
    data.gridApi.refreshCells({
        force: true,
        suppressFlash: true,
    })
    data.gridApi.setGridOption('pinnedBottomRowData', [])

    switch (tab) {
        case SUMMARY_TABS.PROGRAM: {
            // filter HC & deliverables to generate rows.
            const selectedProgram = data[ACTION_TYPES.PROGRAM]?.selected

            if (!selectedProgram) return

            const deliverables = {
                [selectedProgram.label]:
                    data[ACTION_TYPES.DELIVERABLES].data[selectedProgram.label],
            }
            const orgData = data[ACTION_TYPES.HC].data.filter(
                (hcItem: any) => hcItem.program_id === data[ACTION_TYPES.PROGRAM].selected.value,
            )

            const { flatData, groupOHData, orgGroupTotals } = setProgramRows(
                orgData,
                data,
                deliverables,
            )

            // archived groups can appear in the columns if there's HC tied to them
            const groups = data[ACTION_TYPES.GROUPS_BY_ORG].data
                .map((org) => {
                    return {
                        ...org,
                        groups: org.groups.filter((group) => {
                            return !ignoreGroup(group, orgGroupTotals)
                        }),
                    }
                })
                .sort((a: any, b: any) => a.orgName.localeCompare(b.orgName))

            const { colDef }: any = setProgramColumns(groupOHData, groups, initialOrgs)
            const filteredColDef = filterColumns(colDef, initialColumns)

            const rowData = data.showScopedOnly
                ? flatData?.filter((row: any) => row.row_total !== 0)
                : flatData

            data.gridApi.setGridOption('columnDefs', filteredColDef)
            data.gridApi.setGridOption('rowData', rowData)

            break
        }
        case SUMMARY_TABS.GROUP: {
            const allGroups = data[ACTION_TYPES.GROUPS_BY_ORG].data.map((org) => org.groups).flat() // flat array of groups
            const groupsToDeduplicate: any[][] = []
            const groupsToInclude = initialGroups.map((group) => {
                const groupToInclude = allGroups.find((g) => g.group_name === group)
                const allGroupsWithName = allGroups.filter((g) => g.group_name === group)
                if (allGroupsWithName.length > 1) {
                    groupsToDeduplicate.push(allGroupsWithName)
                }
                return groupToInclude?.group_id
            })

            const { flatData, groupOHData, bisData, gapData } = setGroupRows(data, groupsToInclude)
            const orgGroupsToRemove = deduplicateArchivedGroups(groupsToDeduplicate, groupOHData)
            const { colDef }: any = setGroupColumns(data, initialGroups)
            const colDefRemoveDuplicates = colDef.filter((col) => {
                if (!col?.groupId || (col?.groupId && !orgGroupsToRemove.includes(col.groupId))) {
                    return col
                }
            })
            const filteredColDef = filterColumns(colDefRemoveDuplicates, initialColumns)

            const rowData = data.showScopedOnly
                ? flatData?.filter((row: any) => row.row_total !== 0)
                : flatData

            data.gridApi.setGridOption('columnDefs', filteredColDef)
            data.gridApi.setGridOption('rowData', rowData)

            const pinnedRows = getPinnedRows(bisData, groupOHData, gapData, initialRows)
            data.gridApi.setGridOption('pinnedBottomRowData', pinnedRows)

            break
        }
        case SUMMARY_TABS.ORG: {
            const orgsToInclude = initialOrgs.map((org) => {
                const orgToInclude = data[ACTION_TYPES.ORG].options.find((o) => o.label === org)
                return orgToInclude?.value
            })

            const { flatData, groupOHData, bisData, gapData } = setOrgRows(data, orgsToInclude)

            const { colDef }: any = setOrgColumns(groupOHData, data, initialOrgs)

            const filteredColDef = filterColumns(colDef, initialColumns)

            const rowData = data.showScopedOnly
                ? flatData?.filter((row: any) => row.row_total !== 0)
                : flatData

            data.gridApi.setGridOption('columnDefs', filteredColDef)
            data.gridApi.setGridOption('rowData', rowData)

            const pinnedRows = getPinnedRows(bisData, groupOHData, gapData, initialRows)
            data.gridApi.setGridOption('pinnedBottomRowData', pinnedRows)

            break
        }
        case SUMMARY_TABS.PLAN: {
            const { flatData, groupOHData, bisData, gapData } = setPlanRows(data)
            const { colDef }: any = setPlanColumns(data)
            const filteredColDef = filterColumns(colDef, initialColumns)

            const rowData = data.showScopedOnly
                ? flatData?.filter((row: any) => row.row_total !== 0)
                : flatData

            data.gridApi.setGridOption('columnDefs', filteredColDef)
            data.gridApi.setGridOption('rowData', rowData)

            const pinnedRows = getPinnedRows(bisData, groupOHData, gapData, initialRows)
            data.gridApi.setGridOption('pinnedBottomRowData', pinnedRows)

            break
        }
        case SUMMARY_TABS.SPEND:
            data.gridApi.updateGridOptions({
                pinnedBottomRowData: [],
                columnDefs: SpendTableColumns,
                rowData: setSpendRows(initialOrgs, data),
            })
            break

        default:
            break
    }

    const showRowTotals = tab !== SUMMARY_TABS.SPEND

    data.gridApi.setGridOption(
        GRAND_TOTAL_ROW,
        showRowTotals && initialRows.includes('total') ? 'bottom' : undefined,
    )

    if (data.gridApi.getDisplayedRowCount() === 0) {
        data.gridApi.setGridOption('pinnedBottomRowData', [])
        data.gridApi.showNoRowsOverlay()
    } else {
        data.gridApi.redrawRows()
        data.gridApi.hideOverlay()
    }
    data.gridApi.autoSizeAllColumns()
}

export const deliverableCellRenderer = (params: any, isProgram = false) => {
    const value = params?.value
    if (value) {
        return (
            <>
                {value?.priority && (
                    <>
                        <CustomDeliverableBadge $atl={true}>
                            {value.priority.toUpperCase()}
                        </CustomDeliverableBadge>
                        {value?.name ? value.name : value}
                    </>
                )}
                {!value?.priority && <NameBadge>{value?.name ? value.name : value}</NameBadge>}
            </>
        )
    } else {
        if (isProgram) return 'Total'
    }
}

const getFormattedName = (name: string, priority?: string, isProgram?: boolean) => {
    if (priority) {
        return `${isProgram ? 'P' : ''}${priority.toUpperCase()} ${name}`
    }
    return name
}

export const deliverableFilterValueFormatter = (params) => params.value.name || ''
export const deliverableValueFormatter = (params) => {
    const name = params.data?.deliverable_name?.name || ''
    const priority = params.data?.deliverable_name?.priority
    return getFormattedName(name, priority)
}

export const programValueFormatter = (params) => {
    const name = params.data?.program_name || ''
    const priority = params.data?.priority
    return getFormattedName(name, priority, true)
}

export const orgSummaryInitialColDef: any[] = [
    {
        headerName: 'Program',
        field: 'program_name',
        rowGroup: true,
        hide: true,
        pinned: 'left',
    },
    {
        headerName: 'Deliverable',
        field: 'deliverable_name',
        pinned: 'left',
        initialWidth: 300,
        minWidth: 300,
        headerTooltip: 'Deliverable',
        cellRenderer: (params) => deliverableCellRenderer(params, false),
        valueFormatter: deliverableValueFormatter,
        filter: 'agSetColumnFilter',
        filterParams: {
            valueFormatter: deliverableFilterValueFormatter,
            comparator: deliverableComparator,
        },
        floatingFilter: true,
        comparator: deliverableComparator,
    },
]

export const ProgramTableColumns = (setProgramModalVisible) => [
    {
        headerName: 'STL Name',
        field: 'stl_name',
    },
    {
        headerName: 'STL Alias',
        field: 'stl_alias',
    },
    {
        headerName: 'Program',
        field: 'program_name',
        cellRenderer: (params) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {params.value} &nbsp;
                <Icon name='status-info' />
            </div>
        ),
        cellClass: 'clickable-cell',
        onCellClicked: () => setProgramModalVisible(true)
    },
    {
        headerName: 'Subject to OH',
        field: 'is_overhead_applied',
    },
    {
        headerName: 'Registered users',
        field: 'registered_users',
        cellRenderer: (params) => params.value?.join(',') || '',
    },
    {
        headerName: 'Description',
        field: 'description',
    },
    {
        headerName: 'Owned Org.',
        field: 'owned_org',
    },
    {
        headerName: 'Program Theme',
        field: 'program_theme',
        cellRenderer: (params) => params.value?.split('_').join(' '),
    },
    {
        headerName: 'Program Note',
        field: 'program_note',
    },
    {
        headerName: 'Active',
        field: 'is_active',
    },
    {
        headerName: 'Op Goal',
        field: 'is_op_goal',
    },
]

const getSortValue = (data, attribute) => {
    return data[attribute] ? Number(data[attribute]) : Number.MAX_VALUE
}

export const nameComparator = (valueA, valueB, nodeA, nodeB, isDesc) => {
    if (nodeA?.group && nodeB?.group) {
        if (valueA) {
            return valueA?.localeCompare(valueB)
        } else {
            const nameA = nodeA.groupData.program
            const nameB = nodeB.groupData.program
            return nameA.localeCompare(nameB)
        }
    }
}

export const priorityComparator = (valueA, valueB, nodeA, nodeB, isDesc) => {
    if (nodeA?.group && nodeB?.group) {
        const childrenA = nodeA.allLeafChildren
        const childrenB = nodeB.allLeafChildren
        if (!_.isEmpty(childrenA) && !_.isEmpty(childrenB)) {
            const dataA = childrenA[0].data
            const dataB = childrenB[0].data
            const priorityA = getSortValue(dataA, 'priority')
            const priorityB = getSortValue(dataB, 'priority')
            if (priorityA > priorityB) {
                return 1
            } else if (priorityA < priorityB) {
                return -1
            }
            const displaySeqA = getSortValue(dataA, 'display_sequence')
            const displaySeqB = getSortValue(dataB, 'display_sequence')
            return displaySeqA > displaySeqB ? 1 : -1
        }
    }
    return 0
}

export const isLoading = (data) => {
    return (
        data[ACTION_TYPES.DELIVERABLES].loading ||
        data[ACTION_TYPES.PLAN].loading ||
        data[ACTION_TYPES.HC].loading ||
        data[ACTION_TYPES.ORG].loading ||
        data[ACTION_TYPES.PROGRAM].loading ||
        data[ACTION_TYPES.GROUPS_BY_ORG].loading ||
        data[ACTION_TYPES.SPEND].loading
    )
}

export const getPreferenceData = (queries, data) => {
    const timestamp = new Date().toString()
    const summary = queries[TAB] || SUMMARY_TABS.PROGRAM

    let planData
    let revisionId = queries[REVISION]
    if (queries[PLAN]) {
        planData = data[ACTION_TYPES.PLAN].options.find((opt) => opt.value === queries[PLAN])?.data
    } else {
        planData = data[ACTION_TYPES.PLAN].selected?.data
    }

    if (!revisionId) {
        revisionId = data[ACTION_TYPES.REVISION].selected.value
    }

    const revisionData = planData.revisions.find((rev) => rev.revision_id === revisionId)

    const preferenceData = {
        [PLAN]: '',
        [REVISION]: '',
        'Created on': timestamp,
        [ROWS]: ROW_LIST,
        [COLUMNS]: COL_LIST,
        'Summary Page': summary,
    }

    if (queries[ROWS]) {
        preferenceData[ROWS] = queries[ROWS].split(';')
    }

    if (queries[COLUMNS]) {
        preferenceData[COLUMNS] = queries[COLUMNS].split(';')
    }

    if (!planData) {
        console.error("Couldn't find plan data")
        return xlsx.utils.json_to_sheet([{ ...preferenceData }])
    }

    if (summary === SUMMARY_TABS.GROUP) {
        if (queries[SUMMARY_TABS.GROUP]) {
            preferenceData['Selected Groups'] = queries[SUMMARY_TABS.GROUP].split(';')
        } else {
            preferenceData['Selected Groups'] =
                data[ACTION_TYPES.GROUPS_BY_ORG].data[0].groups[0].group_name
        }
    } else {
        if (queries[SUMMARY_TABS.ORG]) {
            preferenceData['Selected Organizations'] = queries[SUMMARY_TABS.ORG].split(';')
        } else {
            preferenceData['Selected Organizations'] =
                data[ACTION_TYPES.GROUPS_BY_ORG].data[0].orgName
        }
    }

    preferenceData[PLAN] = `${planData.plan_type} ${planData.year}`
    preferenceData[REVISION] =
        `Revision ${revisionData.revision_number} - ${revisionData.revision_title}`

    // Create an array of objects, one for each row
    const sheetData: any = []
    const maxArrayLength = Math.max(
        ...Object.values(preferenceData)
            .filter(Array.isArray)
            .map((arr) => arr.length),
    )

    for (let i = 0; i < maxArrayLength; i++) {
        const rowData = {}
        Object.entries(preferenceData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                rowData[key] = value[i] || ''
            } else if (i === 0) {
                rowData[key] = value
            }
        })
        sheetData.push(rowData)
    }

    const sheet = xlsx.utils.json_to_sheet(sheetData)

    const range = xlsx.utils.decode_range(sheet['!ref']!)

    const wrapStyle = {
        alignment: {
            wrapText: true,
            vertical: 'top',
        },
    }

    for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = xlsx.utils.encode_cell({ r: R, c: C })
            if (!sheet[cellAddress]) continue
            sheet[cellAddress].s = wrapStyle
        }
    }

    sheet['!cols'] = [
        { wch: CELL_SM },
        { wch: CELL_SM },
        { wch: CELL_LG },
        { wch: CELL_SM },
        { wch: CELL_SM },
        { wch: CELL_SM },
        { wch: CELL_LG },
        { wch: CELL_LG },
    ]

    return sheet
}

export const getProgramDetails = (program: any) => {
    return [
        {
            label: 'Program Name',
            value: program?.program_name,
        },
        {
            label: 'Program Description',
            value: program?.description,
        },
        {
            label: 'STL Alias',
            value: program?.stl_alias,
        },
        {
            label: 'STL Name',
            value: program?.stl_name,
        },
        {
            label: 'Subject to OH',
            value: program?.is_overhead_applied,
        },
        {
            label: 'Priority',
            value: program?.priority,
        },
        {
            label: 'Registered Users',
            value: program?.registered_users.join(', '),
        },
        {
            label: 'Owned Org.',
            value: program?.owned_org,
        },
        {
            label: 'Is OP Goal',
            value: program?.is_op_goal,
        },
        {
            label: 'Is Active',
            value: program?.is_active.toString(),
        },
        {
            label: 'Program Theme',
            value: program?.program_theme.split('_').join(' '),
        },
        {
            label: 'Program Note',
            value: program?.program_note,
        },
        {
            label: 'Justification',
            value: program?.justification,
        },
        {
            label: 'Kingpin Goals',
            value: program?.kingpin_goals.join(', '),
        },
        {
            label: 'Created By',
            value: program?.created_by,
        },
        {
            label: 'Created Date',
            value: program?.created_at,
        },
        {
            label: 'Last Modified By',
            value: program?.updated_by,
        },
        {
            label: 'Last Modified Date',
            value: program?.updated_at,
        },
    ]
}
