import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-quartz.css'
import {
    ModuleRegistry,
    ClientSideRowModelModule,
    RowDataUpdatedEvent,
    FilterChangedEvent,
    PaginationChangedEvent,
    GridPreDestroyedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { LicenseManager } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import { RowGroupingModule, SetFilterModule } from '@amzn/ag-bird/src/ag-grid-enterprise'

LicenseManager.setLicenseKey(agGridLicense)
ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule, SetFilterModule])

import React, { useEffect } from 'react'
import AuditPaginationComponent from '../AuditPaginationComponent'
import { AUDIT_STATE_ACTIONS } from '../useAuditState'
import { useAuditContext } from '../AuditContext'
import {
    manageLogGroupLoadingState,
    getDefaultGridOptions,
    buildLogGroupKeyFromState,
    isSetFiltersExceedLimit,
    onGoToPage,
    getQueryKeyValues,
    fetchAuditDataForTab,
    isGridUnusable,
    isDataLoading,
    getMinPercentageQueryKeyId,
} from '../AuditUtil'
import '../../summary/SummaryView.scss'
import { Flashbar } from '@amzn/awsui-components-react'
import _ from 'lodash'
import { AUDIT_SERVER_SIDE_FILTERS, BUSINESS_ENTITY_ID, getColumnsForState } from '../Constant'
import useStore from '../../../Store'

export interface AuditTableProps {
    apiClient: any
}

const GenericAuditTable = (props: AuditTableProps) => {
    const { state, dispatch } = useAuditContext()
    const { activeTabId } = state
    const selectedPlanId = state.selectPlanData?.selected?.value || ''
    const logGroupKey = buildLogGroupKeyFromState(state)
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const { apiClient } = props

    const updateFilterModel = (params) => {
        const newFilterModel = state.auditTabDataStates.get(logGroupKey)?.filterModel ?? {}
        const currentFilterModel = params.api.getFilterModel() ?? {}
        if (_.isEmpty(newFilterModel)) {
            Object.keys(currentFilterModel).forEach((colName) => {
                params.api.destroyFilter(colName)
            })
        }
    }

    const onFilterChanged = (params: FilterChangedEvent) => {
        // filter is set with Apply
        const { api } = params
        const logGroupKey = buildLogGroupKeyFromState(state)
        const filterModel = api.getFilterModel()
        const currentModel = state.auditTabDataStates.get(logGroupKey)?.filterModel ?? {}
        const allFilterModelValues = Object.values(filterModel).flatMap((col) => col.values)
        const allSavedModelValues = Object.values(currentModel).flatMap((col) => col.values)

        const newFilterValues = allFilterModelValues.filter(
            (val) => !allSavedModelValues.includes(val),
        )
        const removedFilterValues = allSavedModelValues.filter(
            (val) => !allFilterModelValues.includes(val),
        )
        if (_.isEmpty(newFilterValues) && _.isEmpty(removedFilterValues)) {
            return
        }

        dispatch({
            type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
            payload: {
                filterModel: { ...filterModel },
            },
            log_group_key: logGroupKey,
        })
        dispatch({
            type: AUDIT_STATE_ACTIONS.COMPUTE_TOTAL_PAGES_FOR_LOG,
            log_group_key: logGroupKey,
        })
        // params.columns shows the changed column
        // in our current logic, user can apply only one filter per event
        if (params.columns.length) {
            const colId = params.columns[0]['colId']
            if (!Object.keys(AUDIT_SERVER_SIDE_FILTERS).includes(colId)) {
                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                    payload: {
                        currentPageIndex: 1,
                    },
                    log_group_key: logGroupKey,
                })
                onGoToPage(api, 1)
                return
            }
            if (colId in filterModel) {
                const filterLength = filterModel[colId].values.length
                if (
                    isSetFiltersExceedLimit(api, colId, filterLength, state.activeTabId, dispatch)
                ) {
                    return
                }
            }

            const queryKeyId = getMinPercentageQueryKeyId(state, filterModel, selectedPlanId)
            let tempQueryKey = AUDIT_SERVER_SIDE_FILTERS[queryKeyId] ?? BUSINESS_ENTITY_ID
            const tempQueryKeyValues = getQueryKeyValues(
                tempQueryKey,
                filterModel[queryKeyId]?.values || [],
                selectedBusinessEntity.business_entity_id,
                state.programsByPlan.data[logGroupKey.split('@')[1]],
                state.orgsMap.data,
                state.groupsMap.data,
                state.revisionsMap.data,
                selectedPlanId,
                state.activeTabId,
            )
            // both action_formatted and action_object_type has same query key - action_object_type
            if (tempQueryKey === AUDIT_SERVER_SIDE_FILTERS.action_formatted) {
                tempQueryKey = AUDIT_SERVER_SIDE_FILTERS.action_object_type
            }
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                payload: {
                    queryKey: tempQueryKey,
                    queryKeyValues: tempQueryKeyValues,
                    nextToken: '',
                },
                log_group_key: logGroupKey,
            })
            fetchAuditDataForTab({
                apiClient: apiClient,
                state: state,
                dispatch: dispatch,
                isConcatingData: false,
                queryKey: tempQueryKey,
                queryKeyValues: tempQueryKeyValues,
            })
        }

        // reset filter
        if (_.isEmpty(filterModel)) {
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                payload: {
                    queryKey: BUSINESS_ENTITY_ID,
                    queryKeyValues: selectedBusinessEntity.business_entity_id,
                    nextToken: '',
                },
                log_group_key: logGroupKey,
            })
            fetchAuditDataForTab({
                apiClient: apiClient,
                state: state,
                dispatch: dispatch,
                isConcatingData: false,
                queryKey: BUSINESS_ENTITY_ID,
                queryKeyValues: selectedBusinessEntity.business_entity_id,
            })
            return
        }
    }

    const onPaginationChanged = (event: PaginationChangedEvent) => {
        if (isGridUnusable(state.gridApi)) {
            return
        }
        if (event.newPage ?? false) {
            const newPage = state.gridApi.paginationGetCurrentPage() + 1
            const totalPages = state.gridApi.paginationGetTotalPages()
            const visibleRows = state.gridApi.getDisplayedRowCount()
            const logGroupPlanData = state.auditTabDataStates.get(buildLogGroupKeyFromState(state))
            const currentRowData = logGroupPlanData?.rowData || []
            const isNextTokenValid = visibleRows === currentRowData.length && currentRowData.length
            if (newPage === totalPages && logGroupPlanData?.nextToken && isNextTokenValid) {
                fetchAuditDataForTab({
                    apiClient: apiClient,
                    state: state,
                    dispatch: dispatch,
                    isConcatingData: true,
                    queryKey: logGroupPlanData?.queryKey ?? BUSINESS_ENTITY_ID,
                    queryKeyValues:
                        logGroupPlanData?.queryKeyValues ??
                        selectedBusinessEntity.business_entity_id,
                })
            }
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                payload: {
                    currentPageIndex: newPage,
                },

                log_group_key: buildLogGroupKeyFromState(state),
            })
        }
    }

    const onGridReady = (params) => {
        params.api.updateGridOptions({
            rowData: state.auditTabDataStates.get(logGroupKey)?.rowData ?? [],
            onFilterChanged: onFilterChanged,
            onPaginationChanged: onPaginationChanged,
        })
        params.api.paginationGoToPage(
            state.auditTabDataStates.get(logGroupKey)?.currentPageIndex ?? 1,
        )

        dispatch({
            type: AUDIT_STATE_ACTIONS.SET_SINGLE,
            payload: { gridApi: params.api },
        })
        dispatch({
            type: AUDIT_STATE_ACTIONS.COMPUTE_TOTAL_PAGES_FOR_LOG,
            log_group_key: logGroupKey,
        })
    }

    useEffect(() => {
        if (isGridUnusable(state.gridApi) || isDataLoading(state, selectedBusinessEntity)) {
            return
        }
        state.gridApi.updateGridOptions({
            columnDefs: getColumnsForState({ state: state }),
            rowData: state.auditTabDataStates.get(buildLogGroupKeyFromState(state))?.rowData ?? [],
        })
        state.gridApi.setFilterModel(state.auditTabDataStates.get(logGroupKey)?.filterModel ?? {})
    }, [
        state.activeTabId,
        selectedPlanId,
        state.gridApi,
        isDataLoading(state, selectedBusinessEntity),
    ])

    const onRowDataUpdated = (params: RowDataUpdatedEvent) => {
        params.api.updateGridOptions({
            onPaginationChanged: onPaginationChanged,
            onFilterChanged: onFilterChanged,
        })
        updateFilterModel(params)
        params.api.onFilterChanged()
        dispatch({
            action: AUDIT_STATE_ACTIONS.SET_SINGLE,
            payload: { gridApi: params.api },
        })
        dispatch({
            type: AUDIT_STATE_ACTIONS.COMPUTE_TOTAL_PAGES_FOR_LOG,
            log_group_key: logGroupKey,
        })
    }

    useEffect(() => {
        manageLogGroupLoadingState(state)
    }, [
        state[state.activeTabId].loading,
        state.auditTabDataStates.get(activeTabId)?.rowData,
        state.gridApi,
        state.deliverablesByPlan.loading,
        state.plansMap.loading,
        state.orgsMap.loading,
        state.programsByPlan.loading,
        state.groupsMap.loading,
        state.revisionsMap.loading,
        state.gridApi?.isDestroyed(),
    ])

    const onGridPreDestroyed = (params: GridPreDestroyedEvent) => {
        const { pagination, filter } = params.state
        dispatch({
            action: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
            payload: {
                currentPageIndex: (pagination?.page ?? 0) + 1,
                filterModel: filter?.filterModel ?? {},
            },
            log_group_key: buildLogGroupKeyFromState(state),
        })
        dispatch({
            action: AUDIT_STATE_ACTIONS.COMPUTE_TOTAL_PAGES_FOR_LOG,
            log_group_key: buildLogGroupKeyFromState(state),
        })
    }

    return (
        <>
            <Flashbar items={state[state.activeTabId].flashBarItems} />
            <div className='ag-theme-quartz' style={{ height: 700 }}>
                <AgGridReact
                    rowData={
                        state.auditTabDataStates.get(buildLogGroupKeyFromState(state))?.rowData ??
                        []
                    }
                    onGridReady={onGridReady}
                    gridOptions={getDefaultGridOptions(state.selectedPageSize)}
                    suppressRowTransform={true}
                    getRowId={(params) => params.data.row_id}
                    onRowDataUpdated={onRowDataUpdated}
                    onGridPreDestroyed={onGridPreDestroyed}
                />
            </div>
            <AuditPaginationComponent apiClient={apiClient} />
        </>
    )
}
export default GenericAuditTable
